import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PaymentPage = () => {
  const location = useLocation();
  const { scriptCode } = location.state || {}; // state içinden scriptCode'u alıyoruz
  // Script çalıştırma fonksiyonu
  const runScripts = (element) => {
    const scripts = element.querySelectorAll("script");
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      newScript.type = script.type;
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.innerHTML = script.innerHTML;
      }
      document.body.appendChild(newScript);
    });
  };

  // Modal içeriği render edildiğinde çalıştırılacak kod
  useEffect(() => {
    if (scriptCode) {
      const paymentDivElement = document.getElementById(
        "iyzipay-checkout-form"
      );
      runScripts(paymentDivElement);
    }
  }, [scriptCode]);

  return (
    <div className="bg-white h-screen flex content-center justify-center flex-row">
      {/* Payment Page içeriği */}
      <div
        id="iyzipay-checkout-form"
        className="responsive"
        dangerouslySetInnerHTML={{ __html: scriptCode }} // scriptCode içerik olarak yerleştiriliyor
      />
    </div>
  );
};

export default PaymentPage;
