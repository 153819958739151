import React, { useEffect, useState } from "react";
import TitleArea from "./titleArea";
import PacketBox from "./packetBox";
import Privacy from "./privacy";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import Footer from "./footer";
import Swal from "sweetalert2";
import PaymentModal from "./paymentModal";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function Main({ openPaymentPage }) {
  const [packets, setPackets] = useState([]);
  const [discountCode, setDiscountCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentLevel, setCurrentLevel] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [currentPacket, setCurrentPacket] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [paymentPageContent, setPaymentPageContent] = useState("");
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [packetInfos, setPacketInfos] = useState({
    id: "",
    title: "",
    price: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const checkUserPacketStatus = async (phoneNumber) => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    try {
      const response = await axios.get(
        `${backendUrl}/check-user-packet-status?phone=${rawPhoneNumber}&country_code=90`
      );
      const { current_level, new_user, packet_id } = response.data.data;
      setCurrentPacket(packet_id);
      setCurrentLevel(current_level);
    } catch (error) {
      console.error("Error fetching user packet status:", error);
    }
  };

  const handleBuy = async (packetId) => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setOpenModal(false);
    if (packetId === 1) {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Free pakete geçmek istediğinize emin misiniz? Var olan tüm paketleriniz iptal edilecek.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoadingPayment(true);
          const requestBody = {
            verifcation_code: verifyCode,
            packet_id: packetId,
            gsmNumber: "+90" + rawPhoneNumber,
          };

          try {
            const response = await axios.post(
              `${backendUrl}/cancel-plan`,
              requestBody
            );
            setIsLoadingPayment(false);
            checkUserPacketStatus(rawPhoneNumber);
            if (response.data.status) {
              Swal.fire({
                title: "Başarılı",
                text: "Başarılı bir şekilde Free pakete geçildi!",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          } catch (error) {
            setIsLoadingPayment(false);
            Swal.fire({
              title: "Hata",
              text: "Bir hata oluştu, lütfen tekrar deneyin.",
              icon: "error",
            });
          }
        }
      });
    } else {
      if (rawPhoneNumber === "" || verifyCode === "") {
        Swal.fire({
          title: "Hata",
          text: "Satın almadan önce lütfen telefon numaranızı doğrulayın!",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (
        name === "" ||
        surname === "" ||
        email === "" ||
        address === "" ||
        city === ""
      ) {
        Swal.fire({
          title: "Hata",
          text: "Satın almadan önce lütfen gerekli alanları doldurun!",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else if (!emailRegex.test(email)) {
        Swal.fire({
          title: "Hata",
          text: "Geçerli bir e-posta adresi girin! (Örnek : deneme@gmail.com)",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        try {
          setIsLoadingPayment(true);
          const requestBody = {
            kupon_kodu: discountCode,
            verifcation_code: verifyCode,
            packet_id: packetId,
            name: name,
            surname: surname,
            email: email,
            gsmNumber: "+90" + rawPhoneNumber,
            registration_address: address,
            city: city,
          };

          if (idNumber !== "") {
            requestBody.identity_number = idNumber;
          }
          const response = await axios.post(
            `${backendUrl}/init-payment`,
            requestBody
          );
          const paymentPageContent = response.data.payment_page_url;
          if (response.data.Message === "Bu packeti satin alamazsın") {
            Swal.fire({
              title: "Hata",
              text: "Üyeliğiniz sonlanmadan bu pakete geçemezsiniz!",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
            });
          } else {
            if (paymentPageContent) {
              if (paymentPageContent.startsWith("https://")) {
                window.open(paymentPageContent, "_self");
              } else {
                openPaymentPage(paymentPageContent);
              }
            } else {
              console.error("Payment page URL not found in the response.");
            }
          }
        } catch (error) {
          console.error("Error during purchase:", error);
        } finally {
          setIsLoadingPayment(false);
        }
      }
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/packet?page=1&per_page=100`
      );
      const sortedPackets = response.data.data.items.sort(
        (a, b) => a.order_num - b.order_num
      );
      setPackets(sortedPackets);
    } catch (error) {
      console.error("Error fetching packet data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Scriptleri çalıştırmak için fonksiyon
  const runScripts = (element) => {
    const scripts = element.querySelectorAll("script");
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      newScript.type = script.type;
      if (script.src) {
        newScript.src = script.src;
      } else {
        newScript.innerHTML = script.innerHTML;
      }
      document.body.appendChild(newScript);
    });
  };

  // Modal içeriği render edildiğinde çalıştırılacak kod
  useEffect(() => {
    if (paymentPageContent) {
      const paymentDivElement = document.getElementById(
        "iyzipay-checkout-form"
      );
      if (paymentDivElement) {
        runScripts(paymentDivElement);
      }
    }
  }, [paymentPageContent]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
      const packetBox = document.querySelector(".packetBoxArea");
      if (packetBox) {
        packetBox.style.width = "66.156667%";
      }
    } else {
      document.body.style.overflow = "auto";
      const packetBox = document.querySelector(".packetBoxArea");
      if (packetBox) {
        packetBox.style.width = "66.766667%";
      }
    }

    return () => {
      document.body.style.overflow = "auto";
      const packetBox = document.querySelector(".packetBoxArea");
      if (packetBox) {
        packetBox.style.width = "66.766667%";
      }
    };
  }, [openModal]);

  return (
    <>
      {isLoadingPayment && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="loader w-[150px] h-[150px]">
            <dotlottie-player
              src="https://lottie.host/b6770a8e-2184-463b-9b8c-15057e3f2dec/psed9r7rrz.json"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></dotlottie-player>
          </div>
        </div>
      )}
      <TitleArea />
      <div className="packetBoxArea flex items-center justify-center flex-wrap md:w-2/3 mx-auto mb-8">
        {packets.map((packet) => (
          <PacketBox
            setPacketInfos={setPacketInfos}
            currentPacket={currentPacket}
            discount={discountAmount}
            handleBuy={handleBuy}
            current={currentLevel}
            key={packet.id}
            packet={packet}
            setOpenModal={setOpenModal}
          />
        ))}
      </div>
      <PaymentModal
        packetInfos={packetInfos}
        handleBuy={handleBuy}
        discount={discountAmount}
        discountCode={discountCode}
        setDiscountCode={setDiscountCode}
        verifyCode={verifyCode}
        setVerifyCode={setVerifyCode}
        setCurrentPacket={setCurrentPacket}
        discountAmount={discountAmount}
        setDiscountAmount={setDiscountAmount}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        name={name}
        setName={setName}
        setSurname={setSurname}
        surname={surname}
        email={email}
        setEmail={setEmail}
        address={address}
        setAddress={setAddress}
        city={city}
        setCity={setCity}
        idNumber={idNumber}
        setIdNumber={setIdNumber}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setCurrentLevel={setCurrentLevel}
      />
      <Privacy />
      <Footer />
      <Toaster />
    </>
  );
}

export default Main;
