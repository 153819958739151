import React, { useState, useEffect } from "react";
import logo from "../images/tovivu_main_logo_transparent.png";
import playStore from "../images/get-it-on-google-play.png";
import appStore from "../images/get-it-on-app-store.svg";
import { Link } from "react-router-dom";
function TitleArea({}) {
  return (
    <>
      <div>
        <div className="logoArea w-full flex items-center justify-center">
          <img src={logo} className="w-44 lg:w-[13vw]" alt="Logo" />
        </div>
        <div className="marketArea w-full flex items-center justify-center">
          <Link
            to="https://play.google.com/store/apps/details?id=com.tovivu.client.app&hl=tr"
            target="_blank"
          >
            <img
              src={playStore}
              className="w-36 lg:w-[13vw]"
              alt="Play Store"
            />
          </Link>
          <Link
            to="https://apps.apple.com/tr/app/tovivu/id6477754512"
            target="_blank"
          >
            <img
              src={appStore}
              className="w-36 lg:w-[13vw] ml-4 lg:ml-8"
              alt="App Store"
            />
          </Link>
        </div>
        <div className="textArea w-full flex flex-col items-center justify-center">
          <h1 className="lg:text-[1.2vw] text-white my-16 text-center text-sm px-4">
            Tovivu'nun sınırsız dünyasını avantajlı paketlerle keşfet!
          </h1>
        </div>
      </div>
    </>
  );
}

export default TitleArea;
